export default function reportValidity(id) {
    if(id) {
        let $form: HTMLFormElement = document.querySelector(id);
        
        if($form) {
            return $form.reportValidity();
        }
    }

    return false;
}